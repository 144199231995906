

@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    #DateTimeInput_end,#DateTimeInput_start{
      margin-left: 40px;
      padding: 0px 20px 0px 10px;
      outline: none;
      border: none;
      font-size: 16px;
      color: rgba(0, 0,0, 0.6);
      font-weight: bold;
      clip-path: inset(0% 43% 0% 0%);
    } 
}


@-moz-document url-prefix() {
  #DateTimeInput_end,#DateTimeInput_start{
    margin-left: 40px;
    padding: 0px 20px 0px 10px;
    outline: none;
    border: none;
    font-size: 16px;
    color: rgba(0, 0,0, 0.6);
    font-weight: bold;
    clip-path: inset(0% 30% 0% 0%);
  }  
}



@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  #DateTimeInput_end,#DateTimeInput_start{
    margin-left: 40px;
    padding: 0px 20px 0px 10px;
    outline: none;
    border: none;
    font-size: 16px;
    color: rgba(0, 0,0, 0.6);
    font-weight: bold;
    clip-path: inset(0% 37% 0% 0%);
  }  
}}

.multipleContentOnLine{
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

#Minutes_start,#Minutes_end,#YearSelector_end,#YearSelector_start{
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  border: none;
  font-size: 15px;
  color: rgba(0,0,0, 0.6);
  font-weight: bold;
}

#Hour_start,#Hour_end,#MonthSelector_end,#MonthSelector_start{
  margin-left: 0px;
  padding: 0px 10px 0px 0px;
  border: none;
  font-size: 15px;
  color: rgba(0, 0,0, 0.6);
  font-weight: bold;
}


@media only screen
    and (min-device-width : 0px)
    and (max-device-width : 400px){   
#dateRangeSelectorDiv{
  position: absolute;
top: 80px;
right: 10px;
}
  } 