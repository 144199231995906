.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-moz-document url-prefix() {
  #deviceSelectorDiv {
    top: 15px;
    right: 840px;
  }
  #sensorSelectorDiv {
    top: 15px;
    right: 540px;
  }
  #sensorColSelectorDiv {
    top: 15px;
    right: 240px;
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 900px) {
  #deviceSelectorDiv {
    top: 15px;
    right: 10px;
  }
  #sensorSelectorDiv {
    top: 65px;
    right: 10px;
  }
  #sensorColSelectorDiv {
    top: 115px;
    right: 10px;
  }
  #chartDiv {
    margin-top: 270px;
    position: relative;
  }
  #dateRangeSelectorDiv {
    top: 165px;
    right: 10px;
  }
  #download {
    margin-left: 10px;
  }
}
